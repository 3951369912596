import React, { useState, useEffect } from 'react';
import NewAsistencia from '../Components/NewAsistencia/NewAsistencia';
export default function IndexLayout() {




    return (
        <div >
            <div className='layout'>
                <NewAsistencia />
            </div>
        </div>
    );
}
