import React, { useEffect, useState } from 'react';
import { Link as Anchor } from "react-router-dom";
import './AsistenciasMain.css'
import baseURL from '../../url';
export default function AsistenciasMain() {
    const [asistencias, setAsistencias] = useState([]);

    useEffect(() => {
        cargarProductos();
    }, []);



    const cargarProductos = () => {
        fetch(`${baseURL}/asistenciaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setAsistencias(data.asistencias.reverse().slice(0, 5) || []);
                console.log(data.productos)
            })
            .catch(error => console.error('Error al cargar asistencias:', error));
    };


    return (


        <div className='table-containerProductos'>
            <div className='deFlexMore'>
                <h3>Ultimas {asistencias?.length} asistencias </h3>
                <Anchor to={`/dashboard/asistencias`} className='logo'>
                    Ver más
                </Anchor>
            </div>
            <table className='table'>
                <thead>
                    <tr>
                        <th>ID Asistencia</th>
                        <th>ID Empleado</th>
                        <th>Apellido</th>
                        <th>DNI</th>
                        <th>Tipo</th>
                        <th>Fecha</th>
                    </tr>
                </thead>
                <tbody>
                    {asistencias.map(item => (
                        <tr key={item.idAsistencia}>
                            <td>{item.idAsistencia}</td>
                            <td>{item.idEmpleado}</td>
                            <td>{item.apellido}</td>
                            <td>{item.dni}</td>
                            <td>{item.tipo}</td>
                            <td>{item.createdAt}</td>
                        </tr>
                    ))}
                </tbody>

            </table>
        </div>

    );
};
