import React from 'react'
import Header from '../Header/Header'
import EmpleadosData from '../../Components/Admin/EmpleadosData/EmpleadosData'
import HeaderDash from '../../Components/Admin/HeaderDash/HeaderDash'
export default function Empleados() {
    return (
        <div className='containerGrid'>
            <Header />



            <section className='containerSection'>

                <HeaderDash />
                <div className='container'>
                    <EmpleadosData />
                </div>
            </section>
        </div>
    )
}
