import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faArrowUp, faArrowDown, faSync, faEye } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import './EmpleadosData.css';
import baseURL from '../../url';
import NewEmpleado from '../NewEmpleado/NewEmpleado';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '../../../images/logo.png'
export default function EmpleadosData() {
    const [asistencias, setAsistencias] = useState([]);
    const [empleados, setEmpleados] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalVisible2, setModalVisible2] = useState(false);
    const [empleado, setEmpleado] = useState({});
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [dni, setDni] = useState('');
    const [cargo, setCargo] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState('');
    const [createdAt, setcreatedAt] = useState('');
    const [idEmpleado, setidEmpleado] = useState('');
    const [filtroId, setFiltroId] = useState('');
    const [filtroNombre, setFiltroNombre] = useState('');
    const [filtroDni, setFiltroDni] = useState('');
    const [filtroTel, setFiltroDniTel] = useState('');
    const [filtroEmail, setFiltroEmail] = useState('');
    const [ordenInvertido, setOrdenInvertido] = useState(false);
    const [contactos, setContactos] = useState([]);
    const [fechaSeleccionada, setFechaSeleccionada] = useState('');
    const [fechaDesde, setFechaDesde] = useState('');
    const [fechaHasta, setFechaHasta] = useState('');


    useEffect(() => {
        cargarEmpleados();
        cargarAsistencias();
        cargarContacto();
    }, []);





    const cargarContacto = () => {
        fetch(`${baseURL}/contactoGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setContactos(data.contacto.reverse().slice(0, 1) || []);
                console.log(data.contacto)
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };
    const cargarAsistencias = () => {
        fetch(`${baseURL}/asistenciaCalculoGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setAsistencias(data.asistencias || []);
                console.log(data.asistencias)
            })
            .catch(error => console.error('Error al cargar asistencias:', error));
    };
    const cargarEmpleados = () => {
        fetch(`${baseURL}/empleadosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setEmpleados(data.empleados || []);
            })
            .catch(error => console.error('Error al cargar empleados:', error));
    };

    const eliminarEmpleado = (idEmpleado) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${baseURL}/empleadosDelete.php?idEmpleado=${idEmpleado}`, {
                    method: 'DELETE',
                })
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire(
                            '¡Eliminado!',
                            data.mensaje,
                            'success'
                        );
                        cargarEmpleados();
                    })
                    .catch(error => {
                        console.error('Error al eliminar empleado:', error);
                        toast.error(error);
                    });
            }
        });
    };

    const abrirModal = (empleado) => {
        setEmpleado(empleado);
        setNombre(empleado.nombre);
        setApellido(empleado.apellido);
        setDni(empleado.dni);
        setCargo(empleado.cargo);
        setTelefono(empleado.telefono);
        setEmail(empleado.email);
        setcreatedAt(empleado.createdAt);
        setidEmpleado(empleado.idEmpleado);
        setModalVisible(true);
    };
    const abrirModal2 = (empleado) => {
        setEmpleado(empleado);
        setNombre(empleado.nombre);
        setApellido(empleado.apellido);
        setDni(empleado.dni);
        setCargo(empleado.cargo);
        setTelefono(empleado.telefono);
        setEmail(empleado.email);
        setcreatedAt(empleado.createdAt);
        setidEmpleado(empleado.idEmpleado);
        setModalVisible2(true);
    };
    const cerrarModal = () => {
        setModalVisible(false);
    };
    const cerrarModal2 = () => {
        setModalVisible2(false);
    };
    const handleUpdateEmpleado = (idEmpleado) => {
        const payload = {
            nombre,
            apellido,
            dni,
            cargo,
            telefono,
            email,
        };

        fetch(`${baseURL}/empleadosPut.php?idEmpleado=${idEmpleado}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    Swal.fire(
                        'Error!',
                        data.error,
                        'error'
                    );
                } else {
                    Swal.fire(
                        'Editado!',
                        data.mensaje,
                        'success'
                    );
                    cargarEmpleados();
                    cerrarModal();
                }
            })
            .catch(error => {
                console.error(error.message);
                toast.error(error.message);
            });
    };
    const Filtrados = empleados.filter(item => {
        const idUsuarioMatch = item.idEmpleado.toString().includes(filtroId);
        const nombreMatch = item.nombre.toLowerCase().includes(filtroNombre.toLowerCase());
        const dniMatch = item.dni.toString().includes(filtroDni.toLowerCase());
        const telMatch = item.telefono.toLowerCase().includes(filtroTel.toLowerCase());
        const emailMatch = item.email.toLowerCase().includes(filtroEmail.toLowerCase());

        return idUsuarioMatch && nombreMatch && emailMatch && dniMatch && telMatch;
    });
    const recargar = () => {
        cargarEmpleados();
    };
    const invertirOrden = () => {
        setEmpleados([...empleados].reverse());
        setOrdenInvertido(!ordenInvertido);
    };
    const descargarExcel = () => {
        let data = [];

        // Verificar si se ha seleccionado alguna fecha o filtro
        if (fechaDesde || fechaHasta || filtroId || filtroNombre || filtroDni || filtroTel || filtroEmail) {
            // Si se selecciona alguna fecha, filtrar por fecha
            if (fechaDesde || fechaHasta) {
                let asistenciasFiltradas = [];

                // Si solo se ha seleccionado la fecha desde
                if (fechaDesde && !fechaHasta) {
                    asistenciasFiltradas = asistencias.filter(asistencia => asistencia.fecha === fechaDesde);
                }
                // Si solo se ha seleccionado la fecha hasta
                else if (!fechaDesde && fechaHasta) {
                    asistenciasFiltradas = asistencias.filter(asistencia => asistencia.fecha === fechaHasta);
                }
                // Si se han seleccionado ambas fechas
                else if (fechaDesde && fechaHasta) {
                    asistenciasFiltradas = asistencias.filter(asistencia =>
                        asistencia.fecha >= fechaDesde && asistencia.fecha <= fechaHasta
                    );
                }

                asistenciasFiltradas.forEach(asistencia => {
                    const empleado = empleados.find(empleado => empleado.idEmpleado === asistencia.empleado_id);
                    if (empleado) {
                        data.push({
                            'ID Empleado': empleado.idEmpleado,
                            Nombre: empleado.nombre,
                            Apellido: empleado.apellido,
                            DNI: empleado.dni,
                            Cargo: empleado.cargo,
                            Fecha: asistencia.fecha,
                            'Horas trabajadas': asistencia.horas_trabajadas
                        });
                    }
                });
            }
            // Si se seleccionan filtros, aplicarlos
            else {
                // Filtrar empleados según los filtros seleccionados
                const Filtrados = empleados.filter(item => {
                    const idUsuarioMatch = item.idEmpleado.toString().includes(filtroId);
                    const nombreMatch = item.nombre.toLowerCase().includes(filtroNombre.toLowerCase());
                    const dniMatch = item.dni.toString().includes(filtroDni.toLowerCase());
                    const telMatch = item.telefono.toLowerCase().includes(filtroTel.toLowerCase());
                    const emailMatch = item.email.toLowerCase().includes(filtroEmail.toLowerCase());

                    return idUsuarioMatch && nombreMatch && emailMatch && dniMatch && telMatch;
                });

                // Recorrer empleados filtrados para obtener sus asistencias
                Filtrados.forEach(empleado => {
                    const asistenciasEmpleado = asistencias.filter(asistencia => asistencia.empleado_id === empleado.idEmpleado);
                    asistenciasEmpleado.forEach(asistencia => {
                        data.push({
                            'ID Empleado': empleado.idEmpleado,
                            Nombre: empleado.nombre,
                            Apellido: empleado.apellido,
                            DNI: empleado.dni,
                            Cargo: empleado.cargo,
                            Fecha: asistencia.fecha,
                            'Horas trabajadas': asistencia.horas_trabajadas
                        });
                    });
                    if (asistenciasEmpleado.length === 0) {
                        data.push({
                            'ID Empleado': empleado.idEmpleado,
                            Nombre: empleado.nombre,
                            Apellido: empleado.apellido,
                            DNI: empleado.dni,
                            Cargo: empleado.cargo,
                            Fecha: 'N/A',
                            'Horas trabajadas': 'N/A'
                        });
                    }
                });
            }
        } else {
            // Si no se selecciona ningún filtro ni fecha, descargar todos los empleados con sus asistencias
            empleados.forEach(empleado => {
                const asistenciasEmpleado = asistencias.filter(asistencia => asistencia.empleado_id === empleado.idEmpleado);
                asistenciasEmpleado.forEach(asistencia => {
                    data.push({
                        'ID Empleado': empleado.idEmpleado,
                        Nombre: empleado.nombre,
                        Apellido: empleado.apellido,
                        DNI: empleado.dni,
                        Cargo: empleado.cargo,
                        Fecha: asistencia.fecha,
                        'Horas trabajadas': asistencia.horas_trabajadas
                    });
                });
                if (asistenciasEmpleado.length === 0) {
                    data.push({
                        'ID Empleado': empleado.idEmpleado,
                        Nombre: empleado.nombre,
                        Apellido: empleado.apellido,
                        DNI: empleado.dni,
                        Cargo: empleado.cargo,
                        Fecha: 'N/A',
                        'Horas trabajadas': 'N/A'
                    });
                }
            });
        }

        // Convertir a formato Excel y descargar el archivo
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Empleados y Asistencias');
        XLSX.writeFile(wb, 'empleados_asistencias.xlsx');
    };


    const descargarPDF = () => {
        let data = [];

        // Verificar si se ha seleccionado alguna fecha o filtro
        if (fechaDesde || fechaHasta || filtroId || filtroNombre || filtroDni || filtroTel || filtroEmail) {
            // Si se selecciona alguna fecha, filtrar por fecha
            if (fechaDesde || fechaHasta) {
                let asistenciasFiltradas = [];

                // Si solo se ha seleccionado la fecha desde
                if (fechaDesde && !fechaHasta) {
                    asistenciasFiltradas = asistencias.filter(asistencia => asistencia.fecha === fechaDesde);
                }
                // Si solo se ha seleccionado la fecha hasta
                else if (!fechaDesde && fechaHasta) {
                    asistenciasFiltradas = asistencias.filter(asistencia => asistencia.fecha === fechaHasta);
                }
                // Si se han seleccionado ambas fechas
                else if (fechaDesde && fechaHasta) {
                    asistenciasFiltradas = asistencias.filter(asistencia =>
                        asistencia.fecha >= fechaDesde && asistencia.fecha <= fechaHasta
                    );
                }

                asistenciasFiltradas.forEach(asistencia => {
                    const empleado = empleados.find(empleado => empleado.idEmpleado === asistencia.empleado_id);
                    if (empleado) {
                        data.push({
                            'ID Empleado': empleado.idEmpleado,
                            Nombre: empleado.nombre,
                            Apellido: empleado.apellido,
                            DNI: empleado.dni,
                            Cargo: empleado.cargo,
                            Fecha: asistencia.fecha,
                            'Horas trabajadas': asistencia.horas_trabajadas
                        });
                    }
                });
            }
            // Si se seleccionan filtros, aplicarlos
            else {
                // Filtrar empleados según los filtros seleccionados
                const Filtrados = empleados.filter(item => {
                    const idUsuarioMatch = item.idEmpleado.toString().includes(filtroId);
                    const nombreMatch = item.nombre.toLowerCase().includes(filtroNombre.toLowerCase());
                    const dniMatch = item.dni.toString().includes(filtroDni.toLowerCase());
                    const telMatch = item.telefono.toLowerCase().includes(filtroTel.toLowerCase());
                    const emailMatch = item.email.toLowerCase().includes(filtroEmail.toLowerCase());

                    return idUsuarioMatch && nombreMatch && emailMatch && dniMatch && telMatch;
                });

                // Recorrer empleados filtrados para obtener sus asistencias
                Filtrados.forEach(empleado => {
                    const asistenciasEmpleado = asistencias.filter(asistencia => asistencia.empleado_id === empleado.idEmpleado);
                    asistenciasEmpleado.forEach(asistencia => {
                        data.push({
                            'ID Empleado': empleado.idEmpleado,
                            Nombre: empleado.nombre,
                            Apellido: empleado.apellido,
                            DNI: empleado.dni,
                            Cargo: empleado.cargo,
                            Fecha: asistencia.fecha,
                            'Horas trabajadas': asistencia.horas_trabajadas
                        });
                    });
                    if (asistenciasEmpleado.length === 0) {
                        data.push({
                            'ID Empleado': empleado.idEmpleado,
                            Nombre: empleado.nombre,
                            Apellido: empleado.apellido,
                            DNI: empleado.dni,
                            Cargo: empleado.cargo,
                            Fecha: 'N/A',
                            'Horas trabajadas': 'N/A'
                        });
                    }
                });
            }
        } else {
            // Si no se selecciona ningún filtro ni fecha, descargar todos los empleados con sus asistencias
            empleados.forEach(empleado => {
                const asistenciasEmpleado = asistencias.filter(asistencia => asistencia.empleado_id === empleado.idEmpleado);
                asistenciasEmpleado.forEach(asistencia => {
                    data.push({
                        'ID Empleado': empleado.idEmpleado,
                        Nombre: empleado.nombre,
                        Apellido: empleado.apellido,
                        DNI: empleado.dni,
                        Cargo: empleado.cargo,
                        Fecha: asistencia.fecha,
                        'Horas trabajadas': asistencia.horas_trabajadas
                    });
                });
                if (asistenciasEmpleado.length === 0) {
                    data.push({
                        'ID Empleado': empleado.idEmpleado,
                        Nombre: empleado.nombre,
                        Apellido: empleado.apellido,
                        DNI: empleado.dni,
                        Cargo: empleado.cargo,
                        Fecha: 'N/A',
                        'Horas trabajadas': 'N/A'
                    });
                }
            });
        }

        // Crear el PDF
        const pdf = new jsPDF();
        // Datos de contacto
        const contacto = contactos[0];

        if (contacto) {
            const startX = 15; // Posición X inicial del primer dato de contacto
            const startY = 15; // Posición Y de los datos de contacto
            const spacingY = 5; // Espacio vertical entre cada dato de contacto

            // Agregar el logo a la izquierda de la página con un tamaño más pequeño
            const imgWidth = 10; // Ancho del logo reducido
            const imgHeight = 10; // Alto del logo reducido
            const imgX = startX; // Posición X del logo
            const imgY = startY; // Posición Y del logo
            pdf.addImage(logo, 'PNG', imgX, imgY, imgWidth, imgHeight);

            // Calcular la longitud del texto "REGISTRO DE ASISTENCIA" para alinearlo a la derecha
            const textWidth = pdf.getStringUnitWidth('REGISTRO DE ASISTENCIA') * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
            const textX = pdf.internal.pageSize.width - textWidth - 18; // Posición X para alinear a la derecha

            // Texto "REGISTRO DE ASISTENCIA" alineado a la derecha con tamaño de fuente 16 y en negrita
            pdf.setFontSize(16); // Reducimos el tamaño de fuente
            pdf.setFont('helvetica', 'bold');
            pdf.text(`REGISTRO DE ASISTENCIA`, textX, startY + 7); // Ajusta la posición Y para centrar el texto verticalmente
            pdf.setFont('helvetica', 'normal');

            // Texto "INFORMACIÓN DE LA EMPRESA" en negrita con tamaño de fuente 10 debajo de "REGISTRO DE ASISTENCIA"
            pdf.setFontSize(10);
            pdf.setFont('helvetica', 'bold');
            pdf.text(`INFORMACIÓN DE LA EMPRESA`, startX, startY + spacingY + 12); // Ajusta la posición Y para dejar más espacio para el logo
            pdf.setFont('helvetica', 'normal');

            // Datos de contacto debajo de "INFORMACIÓN DE LA EMPRESA" con tamaño de fuente 9
            pdf.setFontSize(9);
            pdf.text(`NOMBRE:   ${contacto.nombre}`, startX, startY + 2 * spacingY + 12); // Ajusta la posición Y para dejar más espacio para el logo
            pdf.text(`RUC:   ${contacto.ruc}`, startX, startY + 3 * spacingY + 12); // Ajusta la posición Y para dejar más espacio para el logo
            pdf.text(`DIRECCIÓN:   ${contacto.direccion}`, startX, startY + 4 * spacingY + 12); // Ajusta la posición Y para dejar más espacio para el logo
            pdf.text(`ACTIVIDAD ECONÓMICA:   ${contacto.actividad}`, startX, startY + 5 * spacingY + 12); // Ajusta la posición Y para dejar más espacio para el logo
        }









        const columns = [
            { title: 'ID ', dataKey: 'idEmpleado' },
            { title: 'Nombre', dataKey: 'nombre' },
            { title: 'Apellido', dataKey: 'apellido' },
            { title: 'DNI', dataKey: 'dni' },
            { title: 'Cargo', dataKey: 'cargo' },
            { title: 'Fecha', dataKey: 'fecha' },
            { title: 'Horas trabajadas', dataKey: 'horas_trabajadas' },
        ];

        const startY = contacto ? 55 : 10;

        pdf.autoTable({
            head: [columns.map(col => col.title)],
            body: data.map(item => Object.values(item)),
            startY: startY,
        });

        pdf.save('empleados_asistencias.pdf');
    };





    const descargarExcel2 = () => {
        const data = asistencias.filter(item => item.empleado_id === empleado.idEmpleado).flatMap(item =>
        ({

            idEmpleado: item.empleado_id,
            nombre: nombre,
            apellido: apellido,
            dni: dni,
            cargo: cargo,
            email: email,
            Fecha: item.fecha,
            'Horas trbajadas': item.horas_trabajadas,

        })
        );

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Asistencias');
        XLSX.writeFile(wb, 'asistencia.xlsx');
    };

    const descargarPDF2 = () => {
        const pdf = new jsPDF();
        // Datos de contacto
        const contacto = contactos[0];
        if (contacto) {
            const startX = 15; // Posición X inicial del primer dato de contacto
            const startY = 15; // Posición Y de los datos de contacto
            const spacingY = 5; // Espacio vertical entre cada dato de contacto

            // Agregar el logo a la izquierda de la página con un tamaño más pequeño
            const imgWidth = 10; // Ancho del logo reducido
            const imgHeight = 10; // Alto del logo reducido
            const imgX = startX; // Posición X del logo
            const imgY = startY; // Posición Y del logo
            pdf.addImage(logo, 'PNG', imgX, imgY, imgWidth, imgHeight);

            // Calcular la longitud del texto "REGISTRO DE ASISTENCIA" para alinearlo a la derecha
            const textWidth = pdf.getStringUnitWidth('REGISTRO DE ASISTENCIA') * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
            const textX = pdf.internal.pageSize.width - textWidth - 18; // Posición X para alinear a la derecha

            // Texto "REGISTRO DE ASISTENCIA" alineado a la derecha con tamaño de fuente 16 y en negrita
            pdf.setFontSize(16); // Reducimos el tamaño de fuente
            pdf.setFont('helvetica', 'bold');
            pdf.text(`REGISTRO DE ASISTENCIA`, textX, startY + 7); // Ajusta la posición Y para centrar el texto verticalmente
            pdf.setFont('helvetica', 'normal');

            // Texto "INFORMACIÓN DE LA EMPRESA" en negrita con tamaño de fuente 10 debajo de "REGISTRO DE ASISTENCIA"
            pdf.setFontSize(10);
            pdf.setFont('helvetica', 'bold');
            pdf.text(`INFORMACIÓN DE LA EMPRESA`, startX, startY + spacingY + 12); // Ajusta la posición Y para dejar más espacio para el logo
            pdf.setFont('helvetica', 'normal');

            // Datos de contacto debajo de "INFORMACIÓN DE LA EMPRESA" con tamaño de fuente 9
            pdf.setFontSize(9);
            pdf.text(`NOMBRE:   ${contacto.nombre}`, startX, startY + 2 * spacingY + 12); // Ajusta la posición Y para dejar más espacio para el logo
            pdf.text(`RUC:   ${contacto.ruc}`, startX, startY + 3 * spacingY + 12); // Ajusta la posición Y para dejar más espacio para el logo
            pdf.text(`DIRECCIÓN:   ${contacto.direccion}`, startX, startY + 4 * spacingY + 12); // Ajusta la posición Y para dejar más espacio para el logo
            pdf.text(`ACTIVIDAD ECONÓMICA:   ${contacto.actividad}`, startX, startY + 5 * spacingY + 12); // Ajusta la posición Y para dejar más espacio para el logo
        }


        const columns = [
            { title: 'ID ', dataKey: 'empleado_id' },
            { title: 'Nombre', dataKey: 'nombre' },
            { title: 'Apellido', dataKey: 'apellido' },
            { title: 'DNI', dataKey: 'dni' },
            { title: 'Cargo', dataKey: 'cargo' },
            { title: 'Email', dataKey: 'email' },
            { title: 'Fecha', dataKey: 'fecha' },
            { title: 'Horas trabajadas', dataKey: 'horas_trabajadas' },
        ];

        const data = asistencias.filter(item => item.empleado_id === empleado.idEmpleado).flatMap(item =>

        ({
            idEmpleado: item.empleado_id,
            nombre: nombre,
            apellido: apellido,
            dni: dni,
            cargo: cargo,
            email: email,
            fecha: item.fecha,
            'Horas trbajadas': item.horas_trabajadas,
        })
        );
        const startY = contacto ? 55 : 10;
        pdf.autoTable({
            head: [columns.map(col => col.title)],
            body: data.map(item => Object.values(item)),
            startY: startY,
        });

        pdf.save('asistencias.pdf');
    };



    return (
        <div>
            <ToastContainer />



            <div className='deFlexContent'>
                <div className='deFlex2'>
                    <NewEmpleado />
                    <button className='excel' onClick={descargarExcel}><FontAwesomeIcon icon={faArrowDown} /> Excel</button>
                    <button className='pdf' onClick={descargarPDF}><FontAwesomeIcon icon={faArrowDown} /> PDF</button>

                </div>


                <div className='filtrosContain'>
                    <div className='inputsColumn'>
                        <input type="date" id="fechaDesde" value={fechaDesde} onChange={(e) => setFechaDesde(e.target.value)} />
                    </div>
                    <div className='inputsColumn'>
                        <input type="date" id="fechaHasta" value={fechaHasta} onChange={(e) => setFechaHasta(e.target.value)} />
                    </div>
                    <div className='inputsColumn'>

                        <input type="number" value={filtroId} onChange={(e) => setFiltroId(e.target.value)} placeholder='ID Empleado' />
                    </div>

                    <div className='inputsColumn'>

                        <input type="text" value={filtroNombre} onChange={(e) => setFiltroNombre(e.target.value)} placeholder='Nombre' />
                    </div>

                    <div className='inputsColumn'>

                        <input type="text" value={filtroDni} onChange={(e) => setFiltroDni(e.target.value)} placeholder='DNI' />
                    </div>
                    <div className='inputsColumn'>

                        <input type="text" value={filtroTel} onChange={(e) => setFiltroDniTel(e.target.value)} placeholder='Telefono' />
                    </div>
                    <div className='inputsColumn'>

                        <input type="text" value={filtroEmail} onChange={(e) => setFiltroEmail(e.target.value)} placeholder='Email' />
                    </div>
                    <button className='reload' >
                        {Filtrados?.length}
                    </button>

                    <button className='reload' onClick={recargar}><FontAwesomeIcon icon={faSync} /></button>
                    <button className='reverse' onClick={invertirOrden}>
                        {ordenInvertido ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}
                    </button>


                </div>
            </div>
            <div className='table-container'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>ID Empleado</th>
                            <th>Nombre</th>
                            <th>Apellido</th>
                            <th>DNI</th>
                            <th>Cargo</th>
                            <th>Teléfono</th>
                            <th>Email</th>
                            {/* <th>Creación</th> */}
                            <th>Acciones</th>

                        </tr>
                    </thead>
                    <tbody>
                        {Filtrados.map(empleado => (
                            <tr key={empleado.idEmpleado}>
                                <td>{empleado.idEmpleado}</td>
                                <td>{empleado.nombre}</td>
                                <td>{empleado.apellido}</td>
                                <td>{empleado.dni}</td>
                                <td>{empleado.cargo}</td>
                                <td>{empleado.telefono}</td>
                                <td>{empleado.email}</td>
                                {/* <td>{empleado.createdAt}</td> */}
                                <td>
                                    <button className='eliminar' onClick={() => eliminarEmpleado(empleado.idEmpleado)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                    <button className='editar' onClick={() => abrirModal(empleado)}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                    <button className='editar' onClick={() => abrirModal2(empleado)}>
                                        <FontAwesomeIcon icon={faEye} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {modalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='deFlexBtnsModal'>
                            <button className='selected'>
                                Editar
                            </button>
                            <button onClick={cerrarModal} className='close'>
                                &times;
                            </button>
                        </div>
                        <div className='sectiontext'>
                            <div className='flexGrap'>
                                <fieldset>
                                    <legend>ID Empleado</legend>
                                    <input
                                        type="idEmpleado"
                                        disabled
                                        value={idEmpleado}
                                        onChange={(e) => setidEmpleado(e.target.idEmpleado)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Creacion</legend>
                                    <input
                                        type="createdAt"
                                        disabled
                                        value={createdAt}
                                        onChange={(e) => setcreatedAt(e.target.createdAt)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Nombre</legend>
                                    <input
                                        type="text"
                                        value={nombre !== '' ? nombre : empleado.nombre}
                                        onChange={(e) => setNombre(e.target.value)}
                                    />
                                </fieldset>

                                <fieldset>
                                    <legend>Apellido</legend>
                                    <input
                                        type="text"
                                        value={apellido !== '' ? apellido : empleado.apellido}
                                        onChange={(e) => setApellido(e.target.value)}
                                    />
                                </fieldset>

                                <fieldset>
                                    <legend>DNI</legend>
                                    <input
                                        type="text"
                                        value={dni !== '' ? dni : empleado.dni}
                                        onChange={(e) => setDni(e.target.value)}
                                    />
                                </fieldset>

                                <fieldset>
                                    <legend>Cargo</legend>
                                    <input
                                        type="text"
                                        value={cargo !== '' ? cargo : empleado.cargo}
                                        onChange={(e) => setCargo(e.target.value)}
                                    />
                                </fieldset>

                                <fieldset>
                                    <legend>Telefono</legend>
                                    <input
                                        type="text"
                                        value={telefono !== '' ? telefono : empleado.telefono}
                                        onChange={(e) => setTelefono(e.target.value)}
                                    />
                                </fieldset>

                                <fieldset>
                                    <legend>Email</legend>
                                    <input
                                        type="email"
                                        value={email !== '' ? email : empleado.email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </fieldset>

                            </div>
                            <button className='btnPost' onClick={() => handleUpdateEmpleado(empleado.idEmpleado)} >Guardar</button>

                        </div>
                    </div>
                </div>
            )}

            {modalVisible2 && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='deFlexBtnsModal'>
                            <button className='selected'>
                                Trabajo
                            </button>
                            <button onClick={cerrarModal2} className='close'>
                                &times;
                            </button>

                        </div>
                        <div className='sectiontext'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Horas trabajadas</th>
                                    </tr>
                                </thead>
                                {
                                    asistencias.filter(item => item.empleado_id === empleado.idEmpleado).map(item => (
                                        <tbody>
                                            <tr>
                                                <td>{item.fecha}</td>
                                                <td>{item.horas_trabajadas}</td>
                                            </tr>
                                        </tbody>
                                    ))
                                }
                            </table>
                            <div className='deFlex2'>
                                <button className='excel' onClick={descargarExcel2}><FontAwesomeIcon icon={faArrowDown} /> Excel</button>
                                <button className='pdf' onClick={descargarPDF2}><FontAwesomeIcon icon={faArrowDown} /> PDF</button>

                            </div>

                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
