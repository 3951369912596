import IndexLayout from "../Layouts/IndexLayout";
import MainLayout from "../Layouts/MainLayout";
import { createBrowserRouter } from "react-router-dom";
import Usuarios from '../Pages/Usuarios/Usuarios'
import Main from "./Main/Main";
import Empleados from '../Pages/Empleados/Empleados'
import Asistencias from "./Asistencias/Asistencias";
import Contacto from "./Contacto/Contacto";
export const router = createBrowserRouter([

    {
        path: "/",
        element: <IndexLayout />,

    },

    {
        path: "/",
        element: <MainLayout />,
        children: [
            {
                path: `/dashboard`,
                element: <Main />,
            },

            {
                path: `/dashboard/usuarios`,
                element: <Usuarios />,
            },
            {
                path: `/dashboard/empleados`,
                element: <Empleados />,
            },
            {
                path: `/dashboard/asistencias`,
                element: <Asistencias />,
            },
            {
                path: `/dashboard/contacto`,
                element: <Contacto />,
            },
        ],
    },


]);
