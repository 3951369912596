import React, { useEffect, useState } from 'react';
import './EmpleadosMain.css'
import { Link as Anchor } from "react-router-dom";
import baseURL from '../../url';
export default function EmpleadosMain() {
    const [empleados, setEmpleados] = useState([]);

    useEffect(() => {
        cargarUsuarios();
    }, []);

    const cargarUsuarios = () => {
        fetch(`${baseURL}/empleadosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setEmpleados(data.empleados.reverse().slice(0, 5) || []);
            })
            .catch(error => console.error('Error al cargar usuarios:', error));
    };




    return (



        <div className='table-containerUsuarios'>
            <div className='deFlexMore'>
                <h3>Ultimos {empleados?.length} empleados</h3>
                <Anchor to={`/dashboard/usuarios`} className='logo'>
                    Ver más
                </Anchor>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th>ID Empleado</th>
                        <th>Nombre</th>
                        <th>Apellido</th>
                        <th>DNI</th>
                        <th>Cargo</th>
                        <th>Fecha</th>

                    </tr>
                </thead>
                <tbody>
                    {empleados.map(empleado => (
                        <tr key={empleado.idEmpleado}>
                            <td>{empleado.idEmpleado}</td>
                            <td>{empleado.nombre}</td>
                            <td>{empleado.apellido}</td>
                            <td>{empleado.dni}</td>
                            <td>{empleado.cargo}</td>
                            <td>{empleado.createdAt}</td>
                        </tr>
                    ))}
                </tbody>

            </table>
        </div>

    );
};
