import React from 'react'
import Header from '../Header/Header'
import AsistenciasData from '../../Components/Admin/AsistenciasData/AsistenciasData'
import HeaderDash from '../../Components/Admin/HeaderDash/HeaderDash'
export default function Asistencias() {
    return (
        <div className='containerGrid'>
            <Header />



            <section className='containerSection'>

                <HeaderDash />
                <div className='container'>
                    <AsistenciasData />
                </div>
            </section>
        </div>
    )
}
